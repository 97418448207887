import { on } from 'delegated-events';
import moveFocus from '../utils/moveFocus';

on('click', '.js-collapse', (event) => {
  // Stop event here
  event.preventDefault();

  // Get state
  const $toggle = event.target.closest('.js-collapse');
  const $content = document.getElementById(
    $toggle.getAttribute('aria-controls'),
  );

  // Get current state
  const isOpen = $toggle.getAttribute('aria-expanded') === 'true';

  // Toggle state
  $toggle.setAttribute('aria-expanded', isOpen ? 'false' : 'true');
  $content.hidden = isOpen;

  // Focus on opening
  if (!isOpen) {
    moveFocus($content);
  }
});
