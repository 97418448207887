import { createFocusTrap } from 'focus-trap';
import { on } from 'delegated-events';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import whenReady from '../../../javascripts/utils/whenReady';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';
import HeaderMenu from './header.menu';

whenReady().then(() => {
  let focusTrap = null;

  // Enable menu dropdowns
  document
    .querySelectorAll('.header__navigation-item')
    .forEach(($navigationButton) => new HeaderMenu($navigationButton));

  // Navigations overlay
  on('click', '.header__menu-button, .header__close-button', () => {
    const $openButton = document.querySelector('.header__menu-button');
    const $navigations = document.querySelector('.header__navigations');
    const isOpen = $openButton.getAttribute('aria-expanded') !== 'true';

    // Update state
    $openButton.setAttribute('aria-expanded', isOpen ? 'true' : 'false');

    // On open
    if (isOpen) {
      $navigations.classList.add('header__navigations--open');

      focusTrap = createFocusTrap($navigations, {
        onDeactivate() {
          $openButton.click();
        },
        initialFocus: false,
        clickOutsideDeactivates: false,
        returnFocusOnDeactivate: true,
        preventScroll: false,
      });

      disableBodyScroll($navigations);
      focusTrap.activate();

      const $initialFocus = $navigations.querySelector(
        '.header__navigation-link',
      );
      invisibleFocus($initialFocus);
    } else {
      $navigations.classList.remove('header__navigations--open');

      if (focusTrap) {
        focusTrap.deactivate({
          onDeactivate: false,
        });

        focusTrap = null;
      }

      enableBodyScroll($navigations);
    }
  });

  // Search overlay
  on('click', '.header__search-button', () => {
    const $search = document.querySelector('.header__search');
    const $searchButton = $search.querySelector('.header__search-button');
    const $searchOverlay = $search.querySelector('.header__search-overlay');
    const isOpen = $searchButton.getAttribute('aria-expanded') !== 'true';

    // Update state
    $searchButton.setAttribute('aria-expanded', isOpen ? 'true' : 'false');
    $searchOverlay.hidden = !isOpen;

    // On open
    if (isOpen) {
      focusTrap = createFocusTrap($search, {
        onDeactivate() {
          $searchButton.click();
        },
        initialFocus: 'input',
        clickOutsideDeactivates: true,
        returnFocusOnDeactivate: true,
      });

      focusTrap.activate();
    } else if (focusTrap) {
      focusTrap.deactivate({
        onDeactivate: false,
      });

      focusTrap = null;
    }
  });
});
