// Load external JavaScript deps
const $forms = document.querySelectorAll<HTMLFormElement>(
  '.form__form[data-fui-form]',
);

$forms.forEach(($form) => {
  const listeners: Record<string, { element: Element, func: () => unknown }> = {};
  const formConfig = JSON.parse($form.getAttribute('data-fui-form') ?? '{}');
  const fieldModules: Record<
    string,
    Array<{ $form: HTMLFormElement; $field: HTMLElement }>
  > = {};

  const registeredJs: Array<{
    src: string;
    module: string;
    settings: Record<string, unknown>;
  }> = formConfig.registeredJs || [];

  $form
    .querySelectorAll<HTMLElement>('[data-field-config]')
    .forEach(($field) => {
      let fieldConfig: { module: string } | Array<{ module: string }> = JSON.parse(
        $field.getAttribute('data-field-config') ?? '{}',
      );

      if (!Array.isArray(fieldConfig)) {
        fieldConfig = [fieldConfig];
      }

      fieldConfig.forEach((nestedFieldConfig) => {
        if (!fieldModules[nestedFieldConfig.module]) {
          fieldModules[nestedFieldConfig.module] = [];
        }

        fieldModules[nestedFieldConfig.module].push({
          $form,
          $field,
          ...nestedFieldConfig,
        });
      });
    });

  if (registeredJs.length) {
    const $registeredJs = document.createElement('div');
    $registeredJs.setAttribute('data-fui-scripts', formConfig.formId);
    document.body.appendChild($registeredJs);

    registeredJs.forEach((config) => {
      const $script = document.createElement('script');
      $script.src = config.src;
      $script.defer = true;

      $script.onload = () => {
        if (config.module) {
          const fieldConfigs = fieldModules[config.module];

          // Handle multiple fields on a page, creating a new JS class instance for each
          if (
            fieldConfigs
            && Array.isArray(fieldConfigs)
            && fieldConfigs.length
          ) {
            fieldConfigs.forEach((fieldConfig) => {
              if (config.module in window) {
                // eslint-disable-next-line no-new, @typescript-eslint/no-explicit-any
                new (<any>window)[config.module](fieldConfig);
              }
            });
          }

          if (config.settings && config.module in window) {
            // eslint-disable-next-line no-new, @typescript-eslint/no-explicit-any
            new (<any>window)[config.module]({
              $form,
              ...config.settings,
            });
          }

          if (config.module === 'FormieConditions' && config.module in window) {
            $form.form = {
              formTheme: null,
              addEventListener(element: Element, event: string, func: () => unknown) {
                listeners[event] = { element, func };
                const eventName = event.split('.')[0];

                element.addEventListener(eventName, listeners[event].func);
              },
            };

            // eslint-disable-next-line no-new, @typescript-eslint/no-explicit-any
            new (<any>window)[config.module]({
              $form,
            });
          }
        }
      };

      $registeredJs.appendChild($script);
    });
  }
});
