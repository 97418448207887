import whenReady from '../../../javascripts/utils/whenReady';

whenReady().then(() => {
  const cursorsWithLines = new Map();

  // Get all head components
  const $$heading = document.querySelectorAll('.heading');
  $$heading.forEach(($heading) => {
    const $cursor = document.createElement('span');
    const $line = $heading.querySelector('.heading__line');
    const $headline = $heading.querySelector('.headline__text');
    $headline.appendChild($cursor);
    cursorsWithLines.set($cursor, $line);
  });

  // Update line width
  const update = () => {
    cursorsWithLines.forEach(($line, $cursor) => {
      const cursorBox = $cursor.getBoundingClientRect();
      const cursorLeft = cursorBox.left + window.pageXOffset - document.body.clientLeft;
      const lastLineWidth = (cursorLeft - document.body.offsetWidth / 2) * 2;

      // eslint-disable-next-line no-param-reassign
      $line.style.width = `${lastLineWidth}px`;
    });
  };

  // When dom is ready
  update();

  // When fonts are ready
  if (document.fonts && document.fonts.ready) {
    document.fonts.ready.then(update);
  }

  // When window is resized
  window.addEventListener('resize', update);
});
