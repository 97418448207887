import invisibleFocus from '../../../javascripts/utils/invisibleFocus';

export default class HeaderMenu {
  // Move directions
  NEXT_CHILD = 1;

  PREV_CHILD = -1;

  constructor($navigationItem) {
    this.$navigationItem = $navigationItem;
    this.$$children = this.$navigationItem.querySelectorAll(
      '.header__navigation-dropdown-link',
    );

    // No children? No menu.
    if (this.$$children.length === 0) {
      return;
    }

    // Add button semantics
    this.$button = $navigationItem.querySelector('.header__navigation-link');
    this.$button.setAttribute('aria-haspopup', true);
    this.$button.setAttribute('aria-expanded', false);

    // Add menu semantics
    this.$menu = $navigationItem.querySelector('.header__navigation-dropdown');
    this.$menu.setAttribute('role', 'menu');
    this.$menu.hidden = true;

    // Menu children
    this.$firstChild = this.$$children[0]; // eslint-disable-line prefer-destructuring
    this.$lastChild = this.$$children[this.$$children.length - 1];

    // Add event listener for children
    this.$$children.forEach(($child) => {
      // Add menu item semantics
      $child.setAttribute('role', 'menuitem');

      // Handle key presses for menu item
      $child.addEventListener('keydown', (event) => {
        this.onItemKeyDown($child, event);
      });
    });

    // Handle button click
    this.$button.addEventListener('click', this.toggle);

    // Also toggle on down arrow
    this.$button.addEventListener('keydown', ({ keyCode }) => {
      if (keyCode === 40) {
        if (this.$menu.hidden) {
          this.open();
        } else {
          invisibleFocus(
            this.$menu.querySelector('.header__navigation-dropdown-link'),
          );
        }
      }

      if (keyCode === 38) {
        this.close();
      }
    });
  }

  isOpen() {
    return this.$button.getAttribute('aria-expanded') === 'true';
  }

  open = () => {
    this.$navigationItem.classList.add('header__navigation-item--open');
    this.$button.setAttribute('aria-expanded', true);
    this.$menu.hidden = false;
    invisibleFocus(
      this.$menu.querySelector('.header__navigation-dropdown-link'),
    );

    document.addEventListener('click', this.onOutsideClick);
  };

  close = () => {
    this.$navigationItem.classList.remove('header__navigation-item--open');
    this.$button.setAttribute('aria-expanded', false);
    this.$menu.hidden = true;

    document.removeEventListener('click', this.onOutsideClick);
  };

  toggle = () => {
    if (this.isOpen()) {
      this.close();
    } else {
      this.open();
    }
  };

  onOutsideClick = ({ target: $target }) => {
    if (!this.$menu.contains($target) && !this.$button.contains($target)) {
      this.close();
      document.removeEventListener('click', this.onOutsideClick);
    }
  };

  onItemKeyDown = ($child, event) => {
    const move = ($currentChild, direction) => {
      const $nextChildParent = direction === -1
        ? $currentChild.parentNode.previousElementSibling
        : $currentChild.parentNode.nextElementSibling;

      return $nextChildParent
        ? $nextChildParent.querySelector('.header__navigation-dropdown-link')
        : $currentChild;
    };

    if (event.keyCode === 40) {
      event.preventDefault();
      const $nextChild = move($child, this.NEXT_CHILD);
      invisibleFocus($nextChild);
    }

    if (event.keyCode === 38) {
      event.preventDefault();
      const $prevChild = move($child, this.PREV_CHILD);
      invisibleFocus($prevChild);
    }

    if (event.keyCode === 9 && $child === this.$lastChild) {
      this.toggle();
    }

    if (event.keyCode === 27) {
      event.preventDefault();
      this.toggle();
      invisibleFocus(this.$button);
    }
  };
}
