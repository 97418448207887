import { on } from 'delegated-events';

on('click', '.js-confirm', (event) => {
  const $target = event.target.closest('.js-confirm');
  const { confirm } = $target.dataset;

  if (confirm) {
    // eslint-disable-next-line no-alert
    const hasConfirmed = window.confirm(confirm);
    if (!hasConfirmed) {
      event.preventDefault();
    }
  }
});
