import cookies, { CookieAttributes } from 'js-cookie';
import MicroModal from 'micromodal';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { on } from 'delegated-events';
import whenReady from '../../../javascripts/utils/whenReady';
import invisibleFocus from '../../../javascripts/utils/invisibleFocus';

const cookieOptions: CookieAttributes = {
  expires: 365,
  sameSite: 'Strict',
  secure: true,
};

const $globalAlert = document.querySelector<HTMLDivElement>('.global-alert');

if ($globalAlert) {
  const alertId = $globalAlert.dataset.alertId ?? 'global-alert';
  const cookieName = `global_alert_${alertId}`;
  const isDismissed = cookies.get(cookieName);
  const $content = $globalAlert.querySelector<HTMLDivElement>(
    '.global-alert__content',
  );

  const dismiss = () => {
    cookies.set(cookieName, '1', cookieOptions);
  };

  if (!isDismissed && $content) {
    whenReady().then(() => {
      MicroModal.show($globalAlert.id, {
        onShow() {
          disableBodyScroll($content);
          invisibleFocus($content);
        },
        onClose() {
          enableBodyScroll($content);
          dismiss();
        },
      });
    });

    $globalAlert
      .querySelector('.global-alert__button .button')
      ?.addEventListener('click', () => dismiss());
  }

  on('click', '.global-alert__close, .global-alert__background', () => {
    MicroModal.close($globalAlert.id);
  });
}
