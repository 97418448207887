import { createFocusTrap } from 'focus-trap';
import { on } from 'delegated-events';

let focusTrap = null;

on('click', '.js-toggle-navigator-filters', (event) => {
  const $filter = event.target.closest('.navigator__filter');
  const $toggle = $filter.querySelector('.navigator__filter-toggle');
  const $overlay = $filter.querySelector('.navigator__filter-overlay');
  const isOpen = $toggle.getAttribute('aria-expanded') !== 'true';

  // Update state
  $toggle.setAttribute('aria-expanded', isOpen ? 'true' : 'false');
  $overlay.hidden = !isOpen;

  // On open
  if (isOpen) {
    focusTrap = createFocusTrap($filter, {
      onDeactivate() {
        $toggle.click();
      },
      initialFocus: 'input',
      clickOutsideDeactivates: true,
      returnFocusOnDeactivate: true,
    });

    focusTrap.activate();
  } else if (focusTrap) {
    focusTrap.deactivate({
      onDeactivate: false,
    });

    focusTrap = null;
  }
});

on('click', '.js-close-navigator-filters', (event) => {
  const $filter = event.target.closest('.navigator__filter');
  const $toggle = $filter.querySelector('.navigator__filter-toggle');
  $toggle.click();
});
